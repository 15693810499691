.card{
	display: flex;
	flex-direction: column;
	margin: 20px;
	width: 200px;
	height:250px;
	border-radius:12px;
    background:var(--main-decor-color);
}

.card img{
	width: 100%;
	height:70%;
	border-radius:11px 11px 0px 0px;
}

.card .work-content{
	text-align: center;
	margin: auto;
	padding: 10px 5px;
	font-size: 1rem;
	color: var(--main-fonts-color);
	font-family: var(--body-font-family);
	cursor: pointer;
}

.card a{
	text-decoration: none;
}

.card:hover{
	box-shadow: 0 0 1.5rem var(--main-decor-color);
}

.work{
	display: flex; 
	flex: 1;
    flex-wrap: wrap;
    justify-content:center;
	align-items: center;
	padding: 20px;
}