@font-face {
    font-family: "Future";
    src: url("../fonts/Kenney_Future_Narrow.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@600&family=Oswald:wght@500&family=Roboto:wght@300;400&display=swap');

:root{
	--main-background: #000000;
	--main-fonts-color: #ffffff;
	--main-decor-color:#19B20E;
	--title-font-family: 'Future', sans-serif;
	--body-font-family: "noto sans", "sans-serif";
}

*{
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;
}

main{
	padding: 0;
	width: 100%;
	height: 100%;
	background: var(--main-background);
}

::-webkit-Scrollbar{
    width: 5px; 
	background: var(--main-background);
}
::-webkit-Scrollbar-thumb{
	border-radius: 12px;
	background: var(--main-decor-color);
}
