.spaceworm{ 	
	margin: auto;
	height: 100vh;
	color: var(--main-fonts-color);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.spaceworm .background{
	background: url('../pictures/logo3.png') no-repeat;
	background-size: cover;
	background-position:center;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
	opacity:.20;
} 

.spaceworm #pyramidnum{
	background: url('../pictures/newfeaturepic.png') no-repeat;
	background-size: cover;
	background-position:center;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
	opacity:.20;
} 

#icon{
	width:1.2em;
	height:1.2em;
	margin-bottom: -5px;
}

#icon:hover{
	filter: invert(58%) sepia(58%) saturate(4393%) hue-rotate(82deg) brightness(113%) contrast(95%) drop-shadow(0 0 25px var(--main-decor-color));
}