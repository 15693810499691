#home{ 	
	margin: auto;
	height: 100vh;
	color: var(--main-fonts-color);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

#home .background{
	background: url('../pictures/logo3.png') no-repeat;
	background-size: cover;
	background-position:center;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
	opacity:.20;
} 

.intro {
	text-align:center;
    color: var(--main-fonts-color);
	z-index: 1;
	margin: auto;
	padding: 20px;
}

.intro  p{
    margin: 5px;
	font-size:1.2rem;
	font-family: var(--body-font-family);
	text-align:center;
}

.intro  a{
    margin: 10px;
	font-size:2rem;
	text-align:center;
	text-decoration: none;
	color: var(--main-fonts-color);
}

.intro  a:hover{
    color: var(--main-decor-color);
	text-shadow: 0 0 50px var(--main-decor-color);
}

h3{
	padding-bottom: 15px;
    letter-spacing: normal;
    font-family: var(--title-font-family);
	font-style: normal;
	font-size: 60px;
	color: var(--main-fonts-color);
	text-shadow: 0px 0px 15px var(--main-decor-color);
}